import { render, staticRenderFns } from "./LevelIndicator.vue?vue&type=template&id=3bd2a0d6&scoped=true&"
import script from "./LevelIndicator.vue?vue&type=script&lang=js&"
export * from "./LevelIndicator.vue?vue&type=script&lang=js&"
import style0 from "./LevelIndicator.vue?vue&type=style&index=0&id=3bd2a0d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd2a0d6",
  null
  
)

export default component.exports